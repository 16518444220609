<script setup lang="ts">
import SVG_GAME_BLOCK_HOVER from '~/assets/svg/game-block-hover-bg.svg'
import SVG_BANNER_RIGHT_TRIANGLE_URL from '/assets/svg/banner-right-triangle.svg?url'
import { EGameType, type ICommonGameBlockProps } from '@/types/components'

interface Props {
  game: ICommonGameBlockProps
  lazy?: boolean
  iconClass?: any
  nameClass?: any
}

defineOptions({ inheritAttrs: false })

const props = defineProps<Props>()

const gameImgUrl = computed(() => scaleImage(props.game.icon, 180))

const isAutoStart = ref(false)
const gameDetailUrl = computed(() => `/${props.game.gameType === EGameType.CLOUD ? 'games' : 'webgames'}/${props.game.seo}.html${isAutoStart.value ? '?start=1' : ''}`)
</script>

<template>
  <NuxtLink class="group" :to="gameDetailUrl">
    <div class="transition-300 all:transition-300 group-hover:-translate-y-2">
      <div v-bind="$attrs" class="relative aspect-ratio-1 of-hidden" lt-md="rd-3" md="rd-4">
        <img v-if="lazy" v-lazy="gameImgUrl" class="aspect-ratio-1 w-full flex-shrink-0 group-hover:scale-110" :class="iconClass" :alt="game.gameName">
        <img v-else class="aspect-ratio-1 w-full flex-shrink-0 group-hover:scale-110" :class="iconClass" :src="gameImgUrl" :alt="game.gameName">

        <LazyClientOnly>
          <div class="absolute right-0 h-43.01% w-43.01% transition-top-300 -top-40% group-hover:top-0" @mouseenter="isAutoStart = true" @mouseleave="isAutoStart = false">
            <SVG_GAME_BLOCK_HOVER width="100%" height="100%" class="absolute inset-ie-0 top-0" />
            <img :src="SVG_BANNER_RIGHT_TRIANGLE_URL" class="absolute right-8% top-10% size-30% -rotate-45" alt="triangle-icon">
          </div>

          <div
            class="absolute left-0 top-0 size-full flex-center gap-1 bg-green-5 text-3.5 c-gray-1 font-600"
            :class="isAutoStart ? 'op-100' : 'op-0 pointer-events-none'"
            @mouseenter="isAutoStart = true" @mouseleave="isAutoStart = false"
          >
            <img :src="SVG_BANNER_RIGHT_TRIANGLE_URL" class="size-4" alt="triangle-icon">
            <span>{{ $t('common.play') }}</span>
          </div>
        </LazyClientOnly>
      </div>

      <span class="line-clamp-2 mbs-2 break-words text-3.5 c-#fff font-600 lh-4 md:mbs-3" :class="nameClass">{{ game.gameName }}</span>
    </div>
  </nuxtlink>
</template>

<style lang="scss" scoped>
</style>
